<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">
        <el-button type="primary" class="el-icon-plus" @click="handleNew"  size="mini">新增路由</el-button>
      </el-col>
    </el-row>

    <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="接口非网关流量拦截开关配置" name="all"></el-tab-pane>
        <!-- <el-tab-pane label="未开始" name="waiting"></el-tab-pane>
        <el-tab-pane label="售卖中" name="in_sale"></el-tab-pane>
        <el-tab-pane label="已结束" name="it_has_ended"></el-tab-pane> -->
        <el-link type="info" disabled style="font-size: 12px"> 开启后，若获取不到网关则禁止访问</el-link>

        <el-table :data="settingList" :height="wheight-200" v-loading="loading">
          <el-table-column prop="api_desc" label="接口说明" min-width="180"></el-table-column>
          <el-table-column prop="api_path" label="接口地址" min-width="300"></el-table-column>
          <el-table-column label="拦截开关" width="100">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.open_status"
                active-color="#13ce66"
                inactive-color="#ff4949"
                active-value="1"
                inactive-value="0"
                @change="changeOpenStatus(scope.row)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="deleteGatewaySetting(scope.row.api_path)">删除</el-button>
              </template>
          </el-table-column>
        </el-table>
      </el-tabs>
    <sideBar :visible.sync="show_sideBar" :title="'新增绑定信息'">
      <el-form>
        <el-form-item label="接口说明">
          <el-input v-model="form.api_desc" />
        </el-form-item>
        <el-form-item label="接口地址">
          <el-input v-model="form.api_path" />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="save">提交</el-button>
      </div>
    </sideBar>

  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import {
  getGatewaySettingList, setGatewaySetting, deleteGatewaySetting, delGatewaySetting
} from '@/api/gateway'
import SideBar from "@/components/element/sideBar";
import {saveBind} from "@/api/coupon";
import {delPedddingItemsList} from "@/api/goods";

export default {
  props: [],
  provide () {
    return {
      refresh: this.refresh
    }
  },
  components: {
    SideBar
  },
  data () {
    return {
      show_sideBar:false,
      selectGoodsVisible: false,
      setItemStatus: false,
      relItemsIds: [],
      after_goods_status: 0,
      create_time: '',
      activeName: 'all',
      settingList: [],
      downloadfilename: '',
      downloadUrl: '',
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
      },
      form: {
        api_path: '',
        api_desc: '',
      },
      communityVisible: false,
      couponVisible: false,
      goodsVisible: false,
      goodsList: [],
      goodsCount: 0,
      distributors: [],
      goodsPageSize: 10,
      goodsPage:1,
      nowActivity: '',
      cursymbol: '',
      dialogVisible: false,
      currency: {}
    }
  },
  computed: {
    ...mapGetters([
      'wheight'
    ])
  },
  methods: {
    handleNew(){
      this.show_sideBar = true
    },
    resetData() {
      this.form = {
        api_path: '',
        api_desc: '',
      }
    },
    save() {
      if (!this.form.api_path) {
        this.$message({type: 'error', message: '请填写接口地址'})
        return
      }
      if (!this.form.api_desc) {
        this.$message({type: 'error', message: '请填写接口说明'})
        return
      }
      if (this.form.api_path.indexOf('/') <0) {
        this.$message({type: 'error', message: '请填写正确的接口地址'})
        return
      }
      if (this.form.api_path.indexOf('?') >=0) {
        this.$message({type: 'error', message: '请填写正确的接口地址'})
        return
      }
      if (this.form.api_path.indexOf('&') >=0) {
        this.$message({type: 'error', message: '请填写正确的接口地址'})
        return
      }
      if (this.form.api_path.indexOf('=') >=0) {
        this.$message({type: 'error', message: '请填写正确的接口地址'})
        return
      }
      // 如果没有id，则表示为新增
      setGatewaySetting(this.form).then(res => {
        this.$message({type: 'success', message: '操作成功'})
        this.show_sideBar = false
        this.resetData()
        this.getGatewaySettingList()
      })
    },
    changeOpenStatus(row){
      this.form.open_status =row.open_status;
      this.form.api_path =row.api_path;
      this.form.api_desc =row.api_desc;
      setGatewaySetting(this.form).then(res => {
        this.$message({type: 'success', message: '操作成功'})
        this.show_sideBar = false
        this.resetData()
        this.getGatewaySettingList()
      })
    },
    deleteGatewaySetting(api_path) {
      this.$confirm('确认删除此条路由信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.form.api_path = api_path;
        delGatewaySetting(this.form).then(res => {
          this.getGatewaySettingList()
          this.$message({type: 'success', message: '操作成功'})
          this.resetData()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    handleClick(tab, event) {
    },

    dateStrToTimeStamp (str) {
      return Date.parse(new Date(str))/1000
    },
    getGatewaySettingList(){
      getGatewaySettingList(this.params).then(response => {
        this.settingList = response.data.data

      })
    },
    refresh () {
      this.getGatewaySettingList()
    }
  },
  mounted () {
    this.getGatewaySettingList()
  },
  beforeRouteUpdate (to, from, next) {
    next()
    if (to.path.indexOf('_editor') === -1 && to.path.indexOf('_detail') === -1) {
      this.getGatewaySettingList()
    }
  },
  watch: {
    getStatus (val) {
      if (val) {
        this.getGatewaySettingList()
      }
    }
  }
}
</script>
<style scoped lang="scss">
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 10px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.icon-search-plus {
  cursor: pointer;
}
.text-muted {
  color: #999;
}
</style>
